import config from '../../../../../config';
import { EditIcon, PlayIcon, PauseIcon, DeleteIcon } from '../../../../../assets';

import { localDate, localDateAndTime } from '../../../../../helpers';

import style from './row.module.scss';


const PublicationRow = ({i, publication, theme, edithandler, playPauseHandler, deleteHandler, user}) => {

    return (
        <div className={style.main_row_content} style={{background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}>
            <p className={`${style.pub_id} ${style.small_bold_text}`} style={ publication?.status === 'active' ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`} }>{publication?.id}</p>
            <div className={style.thumbnail} style={{backgroundImage:`url(${config.api_statics}/${publication?.folder}/${publication?.coverImage})`}}></div>
            <div className={style.pub_row}>
                
                <div className={style.pub_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.pub_title}>
                            <p className={style.light_text} style={{color:`${theme.text}`, fontSize: '15px'}}>{publication?.title ? publication?.title : 'sin título'}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>

                        <div style={{minWidth:'130px'}}>
                            <p className={style.small_light_text} style={{color:`${theme.text}`}}>activa a partir del:</p>
                            <p className={style.small_light_text} style={{color: `${new Date().getTime() < new Date(publication?.availability).getTime() ? 'red' : 'green'}`}}>
                            {/* <p className={style.small_light_text} style={{color:`${theme.primary}`}}> */}
                                { 
                                    publication?.availability &&
                                    localDateAndTime(publication?.availability)
                                }
                            </p>
                        </div>
                        
                        <div className={style.small_light_text} style={{color:`${theme.primary}`,minWidth:'130px'}}>
                            <p style={{color:`${theme.text}`}}>creada el:</p>
                            {
                                localDateAndTime(new Date(publication?.createdAt).getTime())
                            }
                            {/* <p>
                                {
                                    publication?.createdAt &&
                                    publication?.createdAt?.split('T')[0].split('-').reverse().join('/')
                                }
                                <span> </span>
                                {
                                    publication?.createdAt &&
                                    publication?.createdAt?.split('T')[1].split(':').slice(0,2).join(':')
                                }
                                <span> </span>
                                hs.
                            </p> */}
                        </div>

                        <div className={style.small_light_text} style={{color:`${theme.primary}`,minWidth:'60px'}}>
                            <p style={{color:`${theme.text}`}}>Visitas:</p>
                            <p>{publication?.totalViews}</p>
                        </div>
                        
                        <div className={style.createdby_container}>
                            <img className={style.img_container} src={`${config.api_profile}/${publication?.creator?.image}`} alt={publication?.creator?.firstName} />
                            <div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>creado por</p>
                                <p className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.creator?.firstName}</p>
                            </div>
                        </div>

                        {
                            publication?.editor
                            &&
                            <div className={style.createdby_container}>
                                <img className={style.img_container} src={`${config.api_profile}/${publication?.editor?.image}`} alt={publication?.editor?.firstName} />
                                <div>
                                    <p className={style.small_light_text} style={{color:`${theme.text}`}}>editado por</p>
                                    <p className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.editor?.firstName}</p>
                                </div>
                            </div>
                        }
                        {
                            publication?.editions
                            &&
                            <div className={style.createdby_container}>
                                <img className={style.img_container} src={`${config.api_profile}/${publication?.editions[0]?.admin?.image}`} alt={publication?.admin?.firstName} />
                                <div>
                                    <p className={style.small_light_text} style={{color:`${theme.text}`}}>última edición por:
                                    <span className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.editions[0]?.admin?.firstName}</span>
                                    </p>
                                    <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{localDateAndTime(new Date(publication?.editions[0]?.createdAt).getTime())}</p>
                                </div>
                            </div>
                        }
                    </div>

                </div>
                
                <div className={style.pub_icons}>
                
                    <div onClick={() => edithandler(publication?.id)} style={{cursor: 'pointer'}}>
                        <EditIcon width='19px' height='19px' fill={theme.alttext} />
                    </div>
                    {
                        publication.status === 'pending' ?
                        <div onClick={() => playPauseHandler(publication?.id, 'active')} style={{cursor: 'pointer'}}>
                            <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                        :
                        <div onClick={() => playPauseHandler(publication?.id, 'pending')} style={{cursor: 'pointer'}}>
                            <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                        </div>
                    }
                    {
                        user?.role === 'Admin' &&
                        <div onClick={() => deleteHandler(publication?.id)} style={{cursor: 'pointer'}}>
                            <DeleteIcon width='17px' fill={theme.primary}/>
                        </div>
                    }
                </div>
              
            </div>
        </div>
    )
};

export default PublicationRow;
